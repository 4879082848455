// const preloader = document.querySelector(".preloader");
// console.log(preloader);

window.onload = fadeOutEffect;

function fadeOutEffect() {
  console.log("Fade effect");
  const fadeTarget = document.querySelector(".preloader");
  const fadeEffect = setInterval(() => {
    if (fadeTarget.classList.contains("active")) {
      //   fadeTarget.classlist.add("inactive");
      fadeTarget.classList.remove("active");
      fadeTarget.style.display = "none";
    } else {
      clearInterval(fadeEffect);
    }
  }, 1000);
}
